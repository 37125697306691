/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/**
 * IE11 requires the following for NgClass support on SVG elements
 */
import 'classlist.js';

if (
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))
) {
  const script = document.createElement('script');
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/web-animations/2.3.2/web-animations.min.js';
  document.head.appendChild(script);
}

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */

import 'zone.js';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

import * as process from 'process';
window['process'] = process;

(window as any).global = window;
